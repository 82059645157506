import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getNow } from '../../utils/dateUtil'
import { datePropsToNumber, undefinedPropsToOptional } from '../../utils/objectUtil'
import { setFormValueClearError } from '../../utils/reactUtil'
import { NullPropsToUndefinedType, castNonNullable } from '../../utils/typeUtil'
import { translate } from '../../i18n'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { GetResidenceCategoryMstDto } from '../../dataAccess/webApi/dto/residenceCategoryMstDto'
import {
  getChild,
  ChildrenChoice,
  getChildrenChoices,
  getInterviewedChildrenChoices,
  Child
} from '../common/child'
import { getFacility } from '../common/facility'
import { getResidenceCategoriesAndMstData } from '../common/residenceCategoryMst'
import { getInterviewStatusLabel } from '../common/codeMaster'
import { interviewReservationConfirmationUrl } from '../common/constant/appUrl'
import { yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import {
  interviewStatus,
  permitFlag,
  availabilityOfApplication,
  interviewAcceptMethod
} from '../common/constant/classification'
import { useErrorHandle } from '../common/error/errorHandler'
import { getAddressStringByPostalCode } from '../common/location'
import { useOperationLog } from '../common/operationLog'
import { showLoading } from '../common/store/slices/application'
import {
  selectInterviewReserveEntry,
  setInterviewReserve,
  setInterviewReserveUpdateDatetime,
} from '../common/store/slices/interviewReserve'
import { getUser } from '../common/user'
import { getInterview } from '../common/interview'
import { promiseAllConcurrency } from '../../utils/promiseUtil'
import { selectSystemControl } from '../common/store/slices/systemControl'

/** 最大並列実行数 */
const MAX_WORKER_COUNT = 4

interface UrlParams {
  facilityId: string
  childId: string
}

interface LocationState {
  /** 取得・入力済み情報から復元を試みる場合true */
  isKeep: boolean
}

interface PageState {
  facility?: NullPropsToUndefinedType<GetFacilityDto>
  uninterviewedChilds: ChildrenChoice[]
  childs: ChildrenChoice[]
  interviewedChildsAndStatus: ChildrenChoice[]
}

interface Inputs {
  parentName: string
  parentKana: string
  postalCode: string
  address1: string
  address2: string
  buildingNameRoomNumber: string
  residenceCategory: string
  relationship: string
  children: child[]
}

export interface child {
  childId: string
  childName: string
  childKana: string
  childGender: string
  childBirthday: Date
  childMedicalHistoryFlag: string
  childMedicalHistory: string
  childAllergyFlag: string
  childAllergy: string
  //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
  //maternalHandbookNo: string
  note: string
  facilityNumber: string
  childUpdateDatetime: string
  childInterviewPermitCount: number
  availabilityOfApplication: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory<LocationState | undefined>()
  const { facilityId } = useParams<UrlParams>()
  const { addOperationLog } = useOperationLog()
  const sysCtrl = useSelector(selectSystemControl)
  const isKeep = !!history.location.state?.isKeep
  const reserveEntry = useSelector(selectInterviewReserveEntry)
  const childBirthdayLimitDate = getNow() // 誕生日入力の上限値
  
  const formMethods = useForm<Inputs>({
    defaultValues: {
    ...(isKeep && reserveEntry && {
      ...reserveEntry,
      children: reserveEntry.children?.map(child => ({
        ...child,
        // ミリ秒から Date オブジェクトに変換
        childBirthday: new Date(child.childBirthday),
      })),
    }),
  },
  })
  const watchAllFields= formMethods.watch()
  const [userInterviewPermitCount, setUserInterviewPermitCount] = useState<number>(0)
  const [childInterviewPermitCount, setChildInterviewPermitCount] = useState<number>(0)
  const [isOpenInterviewChildSelect, setIsOpenInterviewChildSelect] = useState(false)
  const [state, setState] = useState<PageState>({ uninterviewedChilds: [], childs: [], interviewedChildsAndStatus: [] })
  const [childId, setChildId] = useState('')
  const [residenceCategoryMst, setResidenceCategoryMst] = useState<GetResidenceCategoryMstDto>()
  const [residenceCategories, setResidenceCategories] = useState<{ value: string; label: string }[]>([])
  const [isDisabledAddress1, setIsDisabledAddress1] = useState(true)
  
  let isDisabledResidenceCategory = false
  if ((isKeep && reserveEntry?.isDisabledResidenceCategory) || userInterviewPermitCount > 0) {
    isDisabledResidenceCategory = true
  }
  let isDisabledBirthday = false
  if (childInterviewPermitCount > 0) {
    isDisabledBirthday = true
  }
  
  // 初期表示
  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001, accessData: [{ userIdRegFlag: yesNo.yes, childId }] })
    dispatch(
      showLoading(
        errorHandle(async () => {
          // 在住種別を取得＆表示
          const residenceCategoriesAndMstData = await getResidenceCategoriesAndMstData()
          setResidenceCategoryMst(residenceCategoriesAndMstData.mstData)
          setResidenceCategories(residenceCategoriesAndMstData.categories)
          const facility = await getFacility(facilityId)
          setState({...state, facility})
          if (!reserveEntry || !reserveEntry.interviewNo) {
            // 新規申込
            if (sysCtrl.interviewDailyAcceptFlag === '0' || facility.interviewAcceptMethod === interviewAcceptMethod.applicationOnly) {
              // 面談申込受付方法：日時の受付を行わない
              if (!(isKeep && reserveEntry)) {
                // お子さま選択ダイアログの情報を取得＆表示
                const facilityWithChilds = await getFacilityWithChilds(facilityId)
                setState({...state, ...facilityWithChilds, facility})
                setIsOpenInterviewChildSelect(true)
              }
            } else {
              // 面談申込受付方法：日時の受付を行う
              if (!isKeep && reserveEntry && reserveEntry.childIds) {
                
                // お子さまの情報を取得
                const facilityWithChilds = await getFacilityWithChilds(facilityId)
                setState({...state, ...facilityWithChilds, facility})
                await initialize(reserveEntry.childIds)
              }
            }
          } else {
            // 予約変更
            if (!isKeep && reserveEntry && reserveEntry.childIds) {
              // お子さまの情報を取得
              const facilityWithChilds = await getFacilityWithChilds(facilityId)
              setState({...state, ...facilityWithChilds, facility})
              await initializeUpdate(reserveEntry.interviewNo, reserveEntry.childIds)
            }
          }
        })
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 
  const setResidenceCategory = useCallback((address1: string) => {
    if (
      !isDisabledResidenceCategory 
      && residenceCategoryMst?.triggerKeywordSetInitialValue
      && address1.includes(residenceCategoryMst.triggerKeywordSetInitialValue)
    ) {
      setFormValueClearError(formMethods, 'residenceCategory', residenceCategoryMst.initialValue)
    }
  }, [isDisabledResidenceCategory, residenceCategoryMst, formMethods])
  
  useEffect(() => {
    const subscription = formMethods.watch((values, { name, type }) => {
      if (name === 'address1' && type === 'change') {
        if (values.address1) {
          setResidenceCategory(values.address1)
        }
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setResidenceCategory])
  
  // 初期設定
  const initialize = useCallback(
    async (childIds: string[]) => {
            const {
              initialInputs,
              userInterviewPermitCount,
              userUpdateDatetime,
            } = await getInitialInputs(childIds)
            formMethods.reset({
              ...formMethods.getValues(),
              ...initialInputs,
            })
            dispatch(setInterviewReserveUpdateDatetime({ userUpdateDatetime }))
            setUserInterviewPermitCount(userInterviewPermitCount)
            setChildInterviewPermitCount(childInterviewPermitCount)
            setChildId(childId)
            
  },[dispatch, errorHandle, formMethods])
  
  // 予約変更の初期設定
  const initializeUpdate = useCallback(
    async (interviewNo: string, childIds: string[]) => {
            const {
              initialInputs,
              userInterviewPermitCount,
              userUpdateDatetime,
              interviewUpdateDatetime,
            } = await getInitialInputsUpdate(interviewNo, childIds)
            formMethods.reset({
              ...formMethods.getValues(),
              ...initialInputs,
            })

            dispatch(setInterviewReserveUpdateDatetime({
              userUpdateDatetime,
              interviewUpdateDatetime
            }))
            setUserInterviewPermitCount(userInterviewPermitCount)
            setChildInterviewPermitCount(childInterviewPermitCount)
            setChildId(childId)
  },[dispatch, errorHandle, formMethods])

  // 次の画面へ
  const onSubmit = useCallback(
    (data: Inputs) => {
      addOperationLog({ operationId: OperationId.OP_00000025 })

      // 1人以上申込みがあるかチェック
      const applicationChildrenFilter = data.children.filter((child) => {
        return child.availabilityOfApplication === availabilityOfApplication.apply
      })
      // 申込みが0人の場合はエラーメッセージを表示する
      if (!applicationChildrenFilter.length) {
        for (let idx = 0; idx < data.children.length; idx++) {
          formMethods.setError(`children.${idx}.availabilityOfApplication`, {
            message: translate('interviewReservationForm.error.overOneApply'),
          })
        }
        return
      }

      const matchValueLabels = residenceCategories.filter((element) => {
        return data.residenceCategory === element.value
      })
      const residenceCategoryName = matchValueLabels[0].label

      const applicationChildren = data.children
      .map(child => ({
        ...child,
        childBirthday: child.childBirthday,
      }))
      .map ((child) => {
        return datePropsToNumber(child)
      })

      const confirmationData = {
        address1: data.address1,
        address2: data.address2,
        buildingNameRoomNumber: data.buildingNameRoomNumber,
        parentKana: data.parentKana,
        parentName: data.parentName,
        postalCode: data.postalCode,
        relationship: data.relationship,
        residenceCategory: data.residenceCategory,

        children: applicationChildren,
      }
      
      dispatch(
        setInterviewReserve({
          ...confirmationData,
          facilityId,
          childIds: reserveEntry?.childIds,
          childId,
          residenceCategoryName,
          interviewNo: reserveEntry?.interviewNo,
          interviewDatetimes: reserveEntry?.interviewDatetimes,
          isDisabledResidenceCategory,
        })
      )
      // 戻るで表示した際に取得・入力済み情報から復元を試みる為に履歴に保管
      history.replace({ ...history.location, state: { isKeep: true } })
      history.push(interviewReservationConfirmationUrl.url())
    },
    [dispatch, history, facilityId, childId, addOperationLog, residenceCategories, isDisabledResidenceCategory]
  )

  const autoCompleteAddress = useCallback(async () => {
    const { postalCode } = formMethods.getValues()
    try {
      const address = await getAddressStringByPostalCode(postalCode)
      if (address) {
        formMethods.setValue('address1', address)
        setResidenceCategory(address)
        setIsDisabledAddress1(true)
      } else {
        setIsDisabledAddress1(false)
      }
    } catch {
      // 取得できなかった場合は何もしない
    }
  }, [formMethods, setResidenceCategory])

  // お子さま選択ダイアログのボタン押下
  const onCloseInterviewChildSelect = useCallback(
    (isCancel: boolean, childIds?: string[]) => {
      dispatch(
        showLoading({
          process: 
          errorHandle(async () => {
            if (isCancel || !childIds) {
              // 選択をキャンセルした場合
              setIsOpenInterviewChildSelect(false)
              history.goBack()
            } else {
              // お子さまを選択した場合
              const filteredChildIds = childIds.filter(childId => childId !== '')
              if (filteredChildIds.length <= 0) {
                return
              }
              setIsOpenInterviewChildSelect(false)
              await initialize(castNonNullable(filteredChildIds))
            }
          }),
          isHiddenMain: false,
        })
      )

    },
    [history, initialize]
  )
  return {
    formMethods,
    autoCompleteAddress,
    onSubmit,
    userInterviewPermitCount,
    childInterviewPermitCount,
    childBirthdayLimitDate,
    isOpenInterviewChildSelect,
    onCloseInterviewChildSelect,
    isDisabledResidenceCategory,
    isDisabledBirthday,
    residenceCategories,
    ...state,
    isDisabledAddress1,
    watchAllFields,
    sysCtrl
  }
}

const getInitialInputs = async (childIds: string[]) => {
  const user = await getUser()
  const childs = await promiseAllConcurrency(
    childIds.map((childId) => async () => {
      const child = await getChild(childId)
      return child
    }),
    MAX_WORKER_COUNT
  )
  //TODO:一旦型定義をanyにしてしまっているので修正する
  const children:any[] = []
  childs.forEach((child:Child) => {
    children.push({
      childId: child.childId,
      childName: child.name,
      childKana: child.kana,
      childGender: child.gender,
      childBirthday: child.birthday,
      //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
      //maternalHandbookNo: child.maternalHandbookNo,
      childUpdateDatetime: child.updateDatetime,
      childInterviewPermitCount: child.interviewPermitCount,
      availabilityOfApplication: yesNo.yes
    })
  })
  return {
    initialInputs: undefinedPropsToOptional({
      parentName: user.name,
      parentKana: user.kana,
      postalCode: user.postalCode,
      address1: user.address1,
      address2: user.address2,
      buildingNameRoomNumber: user.buildingNameRoomNumber,
      residenceCategory: user.residenceCategory,
      relationship: user.relationship,
      children: children,
    }),
    userInterviewPermitCount: user.interviewPermitCount,
    //お子様ごとの誕生日の表示非表示に利用しているので、個別で持たせる
    userUpdateDatetime: user.updateDatetime,
  }
}

/**
 * INPUT情報初期設定（予約変更）
 */
const getInitialInputsUpdate = async (interviewNo: string, childIds: string[]) => {
  const user = await getUser()
  const child = await getChild(childIds[0])
  const interview = await getInterview(interviewNo)

  //TODO:一旦型定義をanyにしてしまっているので修正する
  const children:any[] = []
  children.push({
    childId: child.childId,
    childName: child.name,
    childKana: child.kana,
    childGender: child.gender,
    childBirthday: child.birthday,
    //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
    //maternalHandbookNo: child.maternalHandbookNo,
    childUpdateDatetime: child.updateDatetime,
    childInterviewPermitCount: child.interviewPermitCount,
    availabilityOfApplication: yesNo.yes,
    childMedicalHistoryFlag: interview.childMedicalHistoryFlag,
    childMedicalHistory: interview.childMedicalHistory,
    childAllergyFlag: interview.childAllergyFlag,
    childAllergy: interview.childAllergy,
    note: interview.note,
    facilityNumber: interview.facilityNumber
  })

  return {
    initialInputs: undefinedPropsToOptional({
      parentName: user.name,
      parentKana: user.kana,
      postalCode: user.postalCode,
      address1: user.address1,
      address2: user.address2,
      buildingNameRoomNumber: user.buildingNameRoomNumber,
      residenceCategory: user.residenceCategory,
      relationship: user.relationship,
      children: children,
    }),
    userInterviewPermitCount: user.interviewPermitCount,
    //お子様ごとの誕生日の表示非表示に利用しているので、個別で持たせる
    userUpdateDatetime: user.updateDatetime,
    interviewUpdateDatetime: interview.interviewsUpdateDatetime,
  }
}

/**

 * お子さま一覧取得（お子さま選択ダイアログで使用）

 */
async function getFacilityWithChilds(facilityId: string) {
  const [allChilds, interviewedChilds] = await Promise.all([
    getChildrenChoices(),
    getInterviewedChildrenChoices(facilityId),
  ])

  //A761にて、キャンセル済お子様にラジオボタンを表示して再登録を可能にする対応を行いました。
  //本来SQLで修正をするところですが、お子様選択一覧画面に表示されるキャンセル済お子様のラベルに
  //「（キャンセル済）」のステータスを表示する対応が今後発生する可能性があり、その場合SQLの修正は不要になってしまうため画面での対応としました。

  //interviewedChilds（面談済みお子様）から状態：キャンセル済を除外する
  const exceptForChildsWithCanceledInterview = interviewedChilds.filter((v) => v.status !== interviewStatus.canceled)
  const interviewedChildIdSet = new Set(exceptForChildsWithCanceledInterview.map(({ value }) => value))
  const uninterviewedChilds = allChilds.filter(({ value }) => !interviewedChildIdSet.has(value))
  const childs = interviewedChilds.filter((v) => v.permitFlag === permitFlag.permitted)
  const interviewedChildsAndStatus = exceptForChildsWithCanceledInterview.map((v) => {
    let label = v.label
    if (v.status) {
      label = v.label + '（' + getInterviewStatusLabel(v.status) + '）'
    }
    return { value: v.value, label: label }
  })

  return {
    uninterviewedChilds,
    childs,
    interviewedChildsAndStatus,
  }
}